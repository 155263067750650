import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import LinearProgress from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import StackTable from "../../components/reusableComponents/StackTable";
import PartnerFilterPopOver from "../../components/projects/partnerFilterPopOver";
import SnackBars from "../../components/reusableComponents/SnackBar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SuccessErrorDialogBox from "../../components/reusableComponents/SuccessErrorDialogBox";
import {getSubmittedFormsData,getExportSubmittedFormsData} from "./SubmittedFormApi";
import SubmittedFormsFilterPopOver from "../../components/submittedForms/submittedFormsFilterPopOver";
import dayjs from "dayjs";

const MainGrid = styled(Grid)({
    flex: "1",
    backgroundColor: "white",
    // [theme.breakpoints.down("sm")]: {
    //   marginTop: "60px",
    // },
    marginTop: "80px",
  });

  const initialHeadCells = [
    {
      key: "name",
      label: "Farmer Name",
      type: "name_number_location",
      sort: false,
      capital: true
    },
    {
      key: "crop",
      label: "Crop Name",
      type: "text",
      capital: true
    },
    {
        key: "farm_name",
        label: "Farm Name",
        type: "text",
        capital: true
      },
    {
      key: "extension_worker",
      label: "Extension Worker",
      type: "text",
      capital: true
    },
    {
      key: "total_forms_submitted",
      label: "Submitted Forms",
      type: "submittedForms",
    },
  ];

  export default function SubmittedForms({ }) {

  const dispatch = useDispatch();
  const csvLink = useRef();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const rowsPerPage = 10;
  const [openAddModal, setopenAddModal] = React.useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  const [sortingType, setSortingType] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [applyFilter, setApplyFilter] = useState(false);
  const [searchBarData, setSearchBarData] = useState("");
  const [searchBarText, setSeachBarText] = useState("");
  const [exportData, setExportData] = useState(false);
  const [exportedFileName, setExportedFileName] = useState("");
  const [submittedFormSnackBarType, setSubmittedFormSnackBarType] = useState("success");
  const [submittedFormSnackBarMessage, setSubmittedFormSnackBarMessage] = useState("");
  const [showSubmittedFormSnackBar, setSubmittedFormShowSnackBar] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setopenEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [openListModal, setOpenListModal] = React.useState(false);
  const [listData, setListData] = React.useState([])
  const [openFilterPopOver, setOpenFilterPopOver] = React.useState(null);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState("")
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [selectedProtocols, setSelectedProtocols] = useState([]);
  const [clearFilter, setClearFilter] = useState(false);
  const [submittedFormData, setSubmittedFormData] = useState([]);
  const [submittedFormDataCount, setSubmittedFormDataCount] = useState(-1);
  const [formIdList, setFormIdList] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSuccessBox, setOpenSuccessBox] = useState(false);
  const [openSuccessBoxType,setOpenSuccessBoxType] = useState("success");
  const [successMessage, setSuccessMessage] = useState("");
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));
  const [updatedHeadCell,setUpdatedHeadCell] = useState(initialHeadCells);
  const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
  const [selectedDistrictOptions, setSelectedDistrictOptions] = useState([]);
  const [selectedTalukaOptions, setSelectedTalukaOptions] = useState([]);
  const [selectedCropOptions, setSelectedCropOptions] = useState([]);
  const [exportType,setExportType] = useState("");
  const [selectedExport, setSelectedExport] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  const addHeadCellIfNotExists = (key, label, type, sort = false, capital = false) => {
    // Check if the key already exists in prevState
    if (!updatedHeadCell.some((cell) => cell.key === key)) {
      // Add the new head cell to prevState
      setUpdatedHeadCell((prevState) => [
        ...prevState,
        {
          key,
          label,
          type,
          sort,
          capital,
        },
      ]);
    }
  };

  useEffect(()=>{
    if(exportData && exportType=="select"){
      addHeadCellIfNotExists('select', 'Select', 'select');
    }
  },[exportData])
  

  useEffect(() => {
    if (loggedInUser.role === 'Admin') {
      addHeadCellIfNotExists('partner', 'Partner', 'text', false, true);
      addHeadCellIfNotExists('state', 'State', 'location_specific', false,true);
      addHeadCellIfNotExists('district', 'District', 'location_specific', false,true);
      addHeadCellIfNotExists('taluka', 'Taluka', 'location_specific', false,true);
      addHeadCellIfNotExists('village', 'Village', 'location_specific', false,true);
    } else if (loggedInUser.role === 'Partner Head') {
      addHeadCellIfNotExists('state', 'State', 'location_specific', false,true);
    } else if (loggedInUser.role === 'State Head') {
      addHeadCellIfNotExists('district', 'District', 'location_specific',false, true);
      addHeadCellIfNotExists('taluka', 'Taluka', 'location_specific', false,true);
      addHeadCellIfNotExists('village', 'Village', 'location_specific', false,true);
    } else if (loggedInUser.role === 'District Head') {
      addHeadCellIfNotExists('taluka', 'Taluka', 'location_specific',false, true);
      addHeadCellIfNotExists('village', 'Village', 'location_specific', false,true);
    }
  }, [loggedInUser.role]);

  const handleCancelSelectExport = () => {
    setExportType("");
    setExportData(false);
    setSelectedExport([]);
    setUpdatedHeadCell(prevState => prevState.filter(cell => cell.key !== "select"));
  }


  const handleShowFilterPopOver = (event) => {
    setOpenFilterPopOver(event.currentTarget);
  };

  const handleCloseFilterPopOver = () => {
    setOpenFilterPopOver(null);
    setApplyFilter(false);
    setClearFilter(false);
  };

  const handleExportData = async(type)=>{
    let params = {}
    if(type==="select" && selectedExport.length>0){
      const farmer_ids = selectedExport.map((item) => item.id);
      const farm_ids = selectedExport.map((item) => item.farm_id);
      params = {
        farmer_ids:farmer_ids,
        farm_ids:farm_ids,
      }
      
    }
    if(selectedCropOptions!=[]){
      const cropIds = selectedCropOptions.map(crop => crop.id);
      params['crop_ids'] = cropIds
    }
    if(selectedStatesOptions!=[]){
      const stateIds = selectedStatesOptions.map(state => state.id);
      params['state_ids'] = stateIds
    }
    if(selectedDistrictOptions!=[]){
      const districtIds = selectedDistrictOptions.map(district => district.id);
      params['district_ids'] = districtIds
    }
    if(selectedTalukaOptions!=[]){
      const talukaIds = selectedTalukaOptions.map(taluka => taluka.id);
      params['taluka_ids'] = talukaIds
    }
    if(selectedStatesOptions!=[]){
      const stateIds = selectedStatesOptions.map(state => state.id);
      params['state_ids'] = stateIds
    }
      try {
        const response = await getExportSubmittedFormsData(params)
        if (response != null && response != undefined &&
          response.data !== null && response.data !== undefined &&
          response.data.data !== null && response.data.data !== undefined
        ) {
          const fileUrl = response.data.data
          window.open(fileUrl, '_blank');
        } else {
          setSubmittedFormShowSnackBar(true);
          setSubmittedFormSnackBarType("error");
          setSubmittedFormSnackBarMessage("Something went wrong on sever!");
        }
      } catch (error) {
        setSubmittedFormShowSnackBar(true);
        setSubmittedFormSnackBarType("error");
        setSubmittedFormSnackBarMessage(error.message);
        console.error("API Error:", error);

      } finally {
        setLoading(false);
        setApiCall(false);
        setReload(false);
        setExportData(false);
        setExportType("");
        setSelectedExport([]);
        setUpdatedHeadCell(prevState => prevState.filter(cell => cell.key !== "select"));
      }
    
  }

  

  useEffect(() => {
    setPage(0);
    setOffset(0);
  }, [searchBarData]);

  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      setPage(0);
      setOffset(0);
    }
  }, [applyFilter]);

  useEffect(() => {
    setApiCall(true);
  }, [
    searchBarData,
    offset,
    sortingType,
    sortBy,
    filters,
    applyFilter
  ]);

  useEffect(() => {
    if (clearFilter === true) {
      setSelectedStates([]);
      setSelectedCropOptions([]);
      setSelectedStatesOptions([]);
      setSelectedDistrictOptions([]);
      setSelectedTalukaOptions([]);
      setStartDate(null)
      setEndDate(null)
      setPage(0);
      setOffset(0);
      setApiCall(true);
    }
  }, [clearFilter]);

  useEffect(() => {
    if (apiCall || reload) {
      setLoading(true)
      const params = {
        search: searchBarData,
        offset: offset,
        limit: rowsPerPage,
        sort_key: sortBy,
        sort_by: sortingType,
      }
      if(startDate!==null){
        let formattedStartDate = dayjs(startDate).format("DD-MM-YYYY");
        params['start_date'] = formattedStartDate
      }
      if(endDate!==null){
        let formattedEndDate = dayjs(endDate).format("DD-MM-YYYY");
        params['end_date'] = formattedEndDate
      }

      if(selectedCropOptions!=[]){
        const cropIds = selectedCropOptions.map(crop => crop.id);
        params['crop_ids'] = cropIds
      }
      if(selectedStatesOptions!=[]){
        const stateIds = selectedStatesOptions.map(state => state.id);
        params['state_ids'] = stateIds
      }
      if(selectedDistrictOptions!=[]){
        const districtIds = selectedDistrictOptions.map(district => district.id);
        params['district_ids'] = districtIds
      }
      if(selectedTalukaOptions!=[]){
        const talukaIds = selectedTalukaOptions.map(taluka => taluka.id);
        params['taluka_ids'] = talukaIds
      }
      if(selectedStatesOptions!=[]){
        const stateIds = selectedStatesOptions.map(state => state.id);
        params['state_ids'] = stateIds
      }
      const fetchData = async () => {
        try {
          const response = await getSubmittedFormsData(params)
          if (response != null && response != undefined &&
            response.data !== null && response.data !== undefined &&
            response.data.data !== null && response.data.data !== undefined
          ) {
            setSubmittedFormDataCount(response.data.count)
            setSubmittedFormData(response.data.data);
          } else {
            setSubmittedFormShowSnackBar(true);
            setSubmittedFormSnackBarType("error");
            setSubmittedFormSnackBarMessage("Something went wrong on sever!");
          }
        } catch (error) {
          setSubmittedFormShowSnackBar(true);
          setSubmittedFormSnackBarType("error");
          setSubmittedFormSnackBarMessage(error.message);
          console.error("API Error:", error);
        } finally {
          setLoading(false);
          setApiCall(false);
          setReload(false);
        }
      };
      fetchData();
    }
  }, [dispatch, apiCall, reload,]);

  useEffect(() => {
    if (submittedFormSnackBarMessage !== "" && submittedFormSnackBarMessage !== undefined)
      setSubmittedFormShowSnackBar(true);
  }, [submittedFormSnackBarMessage]);

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          <TopFilterBar
            addButton = {false}
            setAddOpen={setopenAddModal}
            searchBarData={searchBarData}
            setSearchBarData={setSearchBarData}
            searchBarText={searchBarText}
            setSeachBarText={setSeachBarText}
            handleShowFilterPopOver={handleShowFilterPopOver}
            setExportData={setExportData}
            searchBy="Search By Farmer Name"
            loading={loading}
            showFilter={true}
            filterSelected={selectedStates.length !== 0 || selectedPartners.length !== 0 || selectedProtocols.length !== 0}
            isExportButtonMenu={true}
            setExportType={setExportType}
            selectedExport={selectedExport}
            handleCancelSelectExport={handleCancelSelectExport}
            handleExport = {handleExportData}
          />
          {openFilterPopOver && (
            <SubmittedFormsFilterPopOver
              handleClose={handleCloseFilterPopOver}
              openFilterPopOver={openFilterPopOver}
              setApplyFilter={setApplyFilter}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              selectedStatesOptions={selectedStatesOptions}
              setSelectedStatesOptions={setSelectedStatesOptions}
              selectedDistrictOptions={selectedDistrictOptions}
              setSelectedDistrictOptions={setSelectedDistrictOptions}
              selectedTalukaOptions={selectedTalukaOptions}
              setSelectedTalukaOptions={setSelectedTalukaOptions}
              selectedCropOptions={selectedCropOptions}
              setSelectedCropOptions={setSelectedCropOptions}
              filters={filters}
              setFilters={setFilters}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {loading && (
            <Box>
              <LinearProgress color="success" />
            </Box>
          )}
          {isSmallScreen ? (
            <StackTable
              key={submittedFormData}
              headCells={updatedHeadCell}
              tableResponse={submittedFormData}
              totalCount={submittedFormDataCount}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setopenEditModal={setopenEditModal}
              setEditData={setEditData}
              setOpenDeleteModal={setOpenDeleteModal}
              setDeleteData={setDeleteData}
              setPage={setPage}
              page={page}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
            />
          ) : (!loading && (
            <DesktopTable
              key={Math.random()}
              headCells={updatedHeadCell}
              tableResponse={submittedFormData}
              totalCount={submittedFormDataCount}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setopenEditModal={setopenEditModal}
              setEditData={setEditData}
              setOpenDeleteModal={setOpenDeleteModal}
              setDeleteData={setDeleteData}
              setPage={setPage}
              page={page}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
              setOpenListModal={setOpenListModal}
              setListData={setListData}
              selectedExport={selectedExport}
              setSelectedExport={setSelectedExport}
            />
          )
          )}
        </Grid>
        {openSuccessBox && 
        <SuccessErrorDialogBox open={openSuccessBox}
        handleClose={() => setOpenSuccessBox(false)}
        text={successMessage}
        type={openSuccessBoxType}
        />
        }
        {/* <CSVLink
          data={exportedData}
          filename={exportedFileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
          separator={";"}
        /> */}
        {showSubmittedFormSnackBar && (
          <SnackBars
            open={showSubmittedFormSnackBar}
            handleClose={() => {setSubmittedFormShowSnackBar(false);setSubmittedFormSnackBarMessage("");}}
            severity={submittedFormSnackBarType}
            message={submittedFormSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </ThemeProvider>
    </MainGrid>
  );

}