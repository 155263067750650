import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
  Box,
  Chip,
  Snackbar,
  Collapse,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ButtonWithCheckBox from "./ResponseMenu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import BasicDropDown from "../reusableComponents/BasicDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import CustomTooltip from "../helpers/CustomTooltip";
import { containsOnlyEnglishAlphabets } from "../../utils";

export default function EditFormCard({
  index,
  serialNumber = index,
  view = false,
  edit = false,
  question = {},
  setQuestions = () => {},
  selectedLanguage = "english",
  setSelectedLanguage = () => {},
  autofocus=false,
  setOpenSuccessBox = () => { },
  setSuccessMessage = () => { },
  setOpenSuccessBoxType = () => { }
}) {
  const chipStyle = {
    margin: "4px",
    color: "#5E6060",
    backgroundColor: "#F1F1F1",
    fontSize: "16px",
    borderRadius: "1.5px",
  };

  const [responseTypeList, setResponseTypeList] = useState([
    "audio",
    "text",
    "image",
    "dropdown",
    "radio",
  ]);
  const [questionTypeList, setQuestionTypeList] = useState(["Quantity", "MCQ","Date"]);

  const [collapsed, setCollapsed] = useState(true);
  const [questionText, setQuestionText] = useState(question[selectedLanguage]);
  const [questionResponseType, setQuestionResponseType] = useState(
    question["response_type"]
  );
  const [questionType, setQuestionType] = useState(question["question_type"]);
  const handleChangeLanguageResponses = () => {
    const initialResponses = question.responses || {};
    const allLanguages = ["english", "hindi", , "marathi", "gujarati"];

    const initializedResponses = {};
    allLanguages.forEach((language) => {
      if (
        (question.response_type.toLowerCase() === "dropdown" ||
          question.response_type.toLowerCase() === "radio" || (question.response_type==="audio" && question.question_type==="MCQ") || (question.response_type==="text" && question.question_type==="MCQ")) &&
        question["responses"] &&
        question["responses"][language]
      ) {
        initializedResponses[language] = Array.from(
          new Set(
            question["responses"][language].map((value) => value.trim())
          )
        );
      } else {
        initializedResponses[language] = initialResponses[language] || [];
      }
    });

    return initializedResponses;
  };
  const [responses, setResponses] = useState(handleChangeLanguageResponses);
  const [newSynonym, setNewSynonym] = useState("");
  const [isAddingSynonym, setIsAddingSynonym] = useState(false);
  const [addingSynonymFor, setAddingSynonymFor] = useState("");

  const [responseDictionary, setResponseDictionary] = useState(() => {
    // Check if responses is not an empty string and has keys
  if (typeof responses !== "string" && Object.keys(responses).length > 0) {
    // Create an object with languages as first level keys
    const initialDictionary = {};

    // Iterate through the languages
    Object.keys(responses).forEach(language => {
      // Create an object with response values as second level keys
      const responseValues = responses[language].reduce((acc, response) => {
        if (question.dict && question.dict[language][response])
        {
          acc[response] =  question.dict[language][response];
        }
        else
        {
          acc[response] =  [];
        }
        
        
        return acc;
      }, {});

      // Assign the responseValues object to the language key
      initialDictionary[language] = responseValues;
    });

    return initialDictionary;
  } else {
    // If responses is an empty string or has no keys, return an empty dictionary
    return {};
  }
  });
  const handleChangeLanguageUnits = () => {
    const initialUnits = question.units || {};
    const allLanguages = ["english", "hindi", , "marathi", "gujarati"];

    const initializedUnits = {};
    allLanguages.forEach((language) => {
      if (
        (question.response_type.toLowerCase() === "audio" || question.response_type.toLowerCase() === "text") &&
        question.question_type === "Quantity" &&
        question["units"] &&
        question["units"][language]
      ) {
        initializedUnits[language] = Array.from(
          new Set(
            question["units"][language].map((value) => value.trim())
          )
        );
      } else {
        initializedUnits[language] = initialUnits[language] || [];
      }
    });
    return initializedUnits;
  };
  const [units, setUnits] = useState(handleChangeLanguageUnits);

  const [isAddingResponse, setIsAddingResponse] = useState(false);
  const [newResponse, setNewResponse] = useState("");

  const [isAddingUnit, setIsAddingUnit] = useState(false);
  const [newUnit, setNewUnit] = useState("");

  useEffect(() => {
    setQuestionText(
      question[selectedLanguage] ? question[selectedLanguage] : ""
    );
  }, [selectedLanguage]);

  useEffect(() => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["response_type"] = questionResponseType;
      return updatedQuestions;
    });
  }, [questionResponseType]);

  useEffect(() => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["question_type"] = questionType;
      return updatedQuestions;
    });
  }, [questionType]);

  const handleTextChange = (event) => {
    if (!containsOnlyEnglishAlphabets(event.target.value)) {
      setOpenSuccessBox(true);
      setSuccessMessage(`Please enter Question in english`);
      setOpenSuccessBoxType('error');
      setNewSynonym("")
      return;
    }
    setQuestionText(event.target.value);
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["english"] = event.target.value;
      return updatedQuestions;
    });
  };

  useEffect(()=>{
    setQuestionText(questionText);
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      // sheet has param for questions
      updatedQuestions[index]["questions"] = questionText;
      return updatedQuestions;
    });
  },[questionText]);

  const handleChangeQuestionType = (event) => {
    setQuestionType(event.target.value);
    const emptyUnits = {
      english: [],
      hindi: [],
      marathi: [],
      gujarati: [],
    };
    setUnits(emptyUnits);
    const emptyResponses = {
      english: [],
      hindi: [],
      marathi: [],
      gujarati: [],
    };
    setResponses(emptyResponses);
    let emptyDict = {
      "english": {},
      "hindi": {},
      "marathi": {},
      "gujarati": {},
    }
    setResponseDictionary(emptyDict)
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["units"] = emptyUnits;
      return updatedQuestions;
    });
  };

  const updateResponseDictionary = (newResponses) => {
    setResponseDictionary((prevDictionary) => {
      const updatedDictionary = { ...prevDictionary };
  
      // Check if the selected language exists in the dictionary
      if (!updatedDictionary[selectedLanguage]) {
        updatedDictionary[selectedLanguage] = {};
      }

      // Clear entries for responses that are not present in newResponses
      for (const response in updatedDictionary[selectedLanguage]) {
        if (!newResponses[selectedLanguage].includes(response)) {
          delete updatedDictionary[selectedLanguage][response];
        }
      }
  
      // Update the responses for the selected language
      newResponses[selectedLanguage].forEach((response) => {
        updatedDictionary[selectedLanguage][response] =
          updatedDictionary[selectedLanguage][response] || [];
      });
  
      // Update the state with the new dictionary
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index]["dict"] = updatedDictionary;
        return updatedQuestions;
      });
  
      return updatedDictionary;
    });
  };
  
  const handleChangeResponseType = (event) => {
    setQuestionResponseType(event.target.value);
    setQuestionType("");
    const emptyResponses = {
      english: [],
      hindi: [],
      marathi: [],
      gujarati: [],
    };
    setResponses(emptyResponses);
    setUnits(emptyResponses);
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["responses"] = emptyResponses;
      updatedQuestions[index]["units"] = emptyResponses;
      return updatedQuestions;
    });
  };

  const handleChangeResponse = (event) => {
    setNewResponse(event.target.value);
  };

  const handleKeyPressResponse = (event) => {
    if (event.key === "Enter") {
      if (!containsOnlyEnglishAlphabets(newResponse)) {
        setOpenSuccessBox(true);
        setSuccessMessage(`Please enter Response in English`);
        setOpenSuccessBoxType('error');
        setNewResponse("");
        setIsAddingResponse(false);
        return
      }
      setResponses((prevResponses) => {
        // Create a new object with the updated response for the selected language
        const checkRepeatation = prevResponses[selectedLanguage].map(resp => resp.toLowerCase()).indexOf(newResponse.trim().toLowerCase())
        if (checkRepeatation === -1) {
          const updatedResponses = {
            ...prevResponses,
            [selectedLanguage]: Array.from(new Set([...prevResponses[selectedLanguage], newResponse])),
          };
          setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[index]["responses"] = updatedResponses;
            return updatedQuestions;
          });
  
          if ((questionResponseType === "audio" && questionType === "MCQ") || (questionResponseType === "text" && questionType === "MCQ")) {
            updateResponseDictionary(updatedResponses);
          }
          setNewResponse("");
          setIsAddingResponse(false);
  
          return updatedResponses;
        } else {
          setOpenSuccessBox(true);
          setSuccessMessage(`Duplicate Value! Please enter a new value.`);
          setOpenSuccessBoxType('error');
          setNewResponse("");
          setIsAddingResponse(false);
          return prevResponses;
        }

      });
    }
  };

  const handleToggleAddingResponse = () => {
    setIsAddingResponse((prevIsAdding) => !prevIsAdding);
  };

  const handleRemoveResponses = (indexToRemove) => {
    setResponses((prevResponses) => {
      const updatedResponses = {};

      // Iterate over each language in prevResponses
      for (const language in prevResponses) {
        // Check if the indexToRemove is within the bounds of the array
        // Create a new array without the element at indexToRemove
        updatedResponses[language] = [
          ...prevResponses[language].slice(0, indexToRemove),
          ...prevResponses[language].slice(indexToRemove + 1),
        ];
      }

      // Update the question with the new updatedResponses
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index]["responses"] = updatedResponses;
        return updatedQuestions;
      });
      if ((questionResponseType === "audio" && questionType === "MCQ") || (questionResponseType === "text" && questionType === "MCQ")) {
        updateResponseDictionary(updatedResponses);
      }

      return updatedResponses;
    });
  };

  const handleChangeUnit = (event) => {
    setNewUnit(event.target.value);
  };
  const handleKeyPressUnit = (event) => {
    if (event.key === "Enter") {
      if (!containsOnlyEnglishAlphabets(newUnit)) {
        setOpenSuccessBox(true);
        setSuccessMessage(`Please enter Unit in English`);
        setOpenSuccessBoxType('error');
        setNewUnit("");
        setIsAddingUnit(false);
        return
      }
      setUnits((prevUnits) => {
        // Create a new object with the updated response for the selected language
        const checkRepeatation = prevUnits[selectedLanguage].map(unit => unit.toLowerCase()).indexOf(newUnit.trim().toLowerCase())
        if (checkRepeatation === -1) {
          const updatedUnits = {
            ...prevUnits,
            [selectedLanguage]: Array.from(new Set([...prevUnits[selectedLanguage], newUnit])),
          };
          setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[index]["units"] = updatedUnits;
            return updatedQuestions;
          });
  
          setNewUnit("");
          setIsAddingUnit(false);
          return updatedUnits;
        } else {
          setOpenSuccessBox(true);
          setSuccessMessage(`Duplicate Value! Please enter a new value.`);
          setOpenSuccessBoxType('error');
          setNewUnit("");
          setIsAddingUnit(false);
          return prevUnits;
        }
      });
    }
  };

  const handleToggleAddingUnit = () => {
    setIsAddingUnit((prevIsAdding) => !prevIsAdding);
  };

  const handleRemoveUnits = (indexToRemove) => {
    setUnits((prevUnits) => {
      const updatedUnits = {};

      // Iterate over each language in prevUnits
      for (const language in prevUnits) {
        if (indexToRemove >= 0 && indexToRemove < prevUnits[language].length) {
          // Check if the indexToRemove is within the bounds of the array
          // Create a new array without the element at indexToRemove
          updatedUnits[language] = [
            ...prevUnits[language].slice(0, indexToRemove),
            ...prevUnits[language].slice(indexToRemove + 1),
          ];
        } else {
          updatedUnits[language] = [...prevUnits[language]];
        }
      }

      // Update the question with the new updatedUnits
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index]["units"] = updatedUnits;
        return updatedQuestions;
      });

      return updatedUnits;
    });
  };

  const handleToggleAddingSynonym = (response) => {
    setIsAddingSynonym((prevIsAdding) => !prevIsAdding);
    setAddingSynonymFor(response);
    if (!isAddingSynonym) {
      setNewSynonym("");
    }
  };

  const handleRemoveSynonym = (response, synonymIndex) => {
    const updatedDictionary = { ...responseDictionary };
    updatedDictionary[selectedLanguage][response].splice(synonymIndex, 1);
    setResponseDictionary(updatedDictionary);
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index]["dict"] = updatedDictionary;
      return updatedQuestions;
    });
  };

  const handleAddSynonym = (response) => {
    if (!containsOnlyEnglishAlphabets(newSynonym)) {
      setOpenSuccessBox(true);
      setSuccessMessage(`Please enter Synonym in english`);
      setOpenSuccessBoxType('error');
      setNewSynonym("")
      return;
    }
    const updatedDictionary = { ...responseDictionary }; // Create a copy of the dictionary
    const synonyms = updatedDictionary[selectedLanguage][response] || [];
    const checkRepeatation = synonyms.map(resp => resp.toLowerCase()).indexOf(newSynonym.trim().toLowerCase())
    if (checkRepeatation === -1) {
      if (!updatedDictionary[selectedLanguage][response]) {
        updatedDictionary[selectedLanguage][response] = [];
      }
      if (newSynonym.trim() !== "") {
        updatedDictionary[selectedLanguage][response].push(newSynonym.trim());
        setNewSynonym("");
        setResponseDictionary(updatedDictionary);
        // Update the question with the new possible_responses string
        setQuestions((prevQuestions) => {
          const updatedQuestions = [...prevQuestions];
          updatedQuestions[index]["dict"] = updatedDictionary;
          return updatedQuestions;
        });
      }
    } else {
      setOpenSuccessBox(true);
      setSuccessMessage(`Duplicate Value! Please enter a new value.`);
      setOpenSuccessBoxType('error');
    }
  };

  const handleDelete = () => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions.splice(index, 1);
      return updatedQuestions;
    });
  };

  useEffect(() => {
    setQuestionResponseType(question["response_type"]);
    setQuestionType(question["question_type"]);
    setQuestionText(question[selectedLanguage]);
    setResponses(
      question["responses"] !== undefined ? question["responses"] : {}
    );
    setUnits(question["units"] !== undefined ? question["units"] : {});
    let emptyDict = {
      "english": {},
      "hindi": {},
      "marathi": {},
      "gujarati": {},
    }
    setResponseDictionary(question["dict"] !== undefined ? question["dict"] : emptyDict)
  }, [question]);

  useEffect(()=>{
    if((questionResponseType=== "audio" && questionType === "MCQ") || (questionResponseType === "text" && questionType === "MCQ")){
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index] = {
          ...updatedQuestions[index],
          dict: responseDictionary,
        };
        return updatedQuestions;
      });
    }
  },[])

  return (
    <Grid
      item
      xs={12}
      sm={12}
      sx={{
        padding: 2,
        marginLeft: 1,
        marginTop: 3,
        borderRadius: "1rem",
        width: "80%",
        backgroundColor: "#F5F5F5",
        overflowX: "auto",
      }}
    >
      <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
        <Box sx={{ marginRight: 1 }}>
          <strong>{serialNumber + 1}.&nbsp;</strong>
        </Box>
        <TextField
          variant="standard"
          size="small"
          value={questionText}
          onChange={handleTextChange}
          // onBlur={handleBlur}
          multiline
          fullWidth
          sx={{
            color: "#444444",
          }}
          InputProps={{
            disableUnderline: true,
            style: { fontSize: "16px" },
          }}
          disabled={view}
          autoFocus={autofocus}
          error={questionText !== undefined && questionText.trim() === ""}
          helperText={
            questionText !== undefined && questionText.trim() === ""
              ? "This field is empty"
              : ""
          }
        />
      </Grid>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        <Grid item xs={3} sm={6} md={12} sx={{ display: "flex" }}>
          <Box
            sx={{
              paddingX: 2,
              paddingY: 1,
              marginLeft: 2,
              marginTop: 2,
              borderRadius: "0.5rem",
              backgroundColor: "",
              minWidth: "300px",
            }}
          >
            <Box
              sx={{
                color: "#78828C",
                marginRight: 3,
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Align items at the start and end
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#7B7B7B",
                }}
              >
                Response Type
              </Typography>
              <CustomTooltip title="The 'response_type' refers to the type of response you're seeking from users in your mobile application. Options for response types include: Audio, Image, Text, Radio, and Dropdown." />
            </Box>
            <BasicDropDown
              value={questionResponseType}
              handleChange={handleChangeResponseType}
              menuList={responseTypeList}
              disable={view}
            />
          </Box>
          <Box
            sx={{
              paddingX: 2,
              paddingY: 1,
              marginLeft: 2,
              marginTop: 2,
              borderRadius: "0.5rem",
              minWidth: "300px",
              alignItems: "center",
            }}
          >
            {/* <Box
              sx={{
                color: "#78828C",
                marginRight: 3,
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Align items at the start and end
              }}
            > */}
            <Box sx={{ color: "#78828C", marginRight: 2, marginBottom: 2 }}>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#7B7B7B",
                  marginBottom: "10px",
                }}
              >
                Question Type
              </Typography>
              {/* <CustomTooltip title="The 'response_type' refers to the type of response you're seeking from users in your mobile application. Options for response types include: Audio, Image, Text, Radio, and Dropdown." /> */}
            </Box>
            <BasicDropDown
              text={(questionResponseType === "audio" || questionResponseType=="text") ? "Select question type" : questionResponseType}
              value={questionType}
              handleChange={handleChangeQuestionType}
              menuList={questionTypeList}
              // disable={view}
              disable={view || !(questionResponseType === "audio" || questionResponseType=="text")}
            />
          </Box>
        </Grid>
        {(questionResponseType === "dropdown" ||
          questionResponseType === "radio" ||
          (questionResponseType === "audio" && questionType === "MCQ") || questionResponseType === "text" && questionType === "MCQ") && (
          <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingX: 2,
                paddingY: 1,
                marginLeft: 2,
                marginTop: 1,
              }}
            >
              <Box
                sx={{
                  color: "#78828C",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Align items at the start and end
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#7B7B7B",
                  }}
                >
                  Responses
                </Typography>
                <CustomTooltip title="Under the 'Responses' column, include all the potential responses listed in the 'possible_responses' column for each question. Each response serves as a unique 'Response' entry" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  p: 1,
                  border: 1,
                  borderRadius: 3,
                  borderColor: "#E6E9EA",
                  backgroundColor: "#ffffff",
                  maxHeight: 100,
                  overflow: "auto",
                }}
              >
                {!isAddingResponse &&
                responses &&
                responses[selectedLanguage] &&
                responses[selectedLanguage].length === 0 ? (
                  <Chip label="Add a response" style={chipStyle} disabled={view} />
                ) : (
                  responses[selectedLanguage].map((response, index) => (
                    <Chip
                      key={index}
                      label={response}
                      onDelete={() => handleRemoveResponses(index)}
                      deleteIcon={<ClearIcon />}
                      style={chipStyle}
                      disabled={view}
                    />
                  ))
                )}

                {isAddingResponse && (
                  <TextField
                    value={newResponse}
                    onChange={handleChangeResponse}
                    onKeyPress={handleKeyPressResponse}
                    placeholder="Add a response"
                    style={{ width: "150px", fontSize: "12px" }}
                    inputProps={{
                      style: { height: "30px", padding: "6px 12px" }, // Adjust height and padding
                    }}
                    disabled={view}
                  />
                )}
                <IconButton onClick={handleToggleAddingResponse} disabled={view}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        )}

        {((questionType === "Quantity" && questionResponseType === "audio") || (questionType === "Quantity" && questionResponseType === "text")) && (
          <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingX: 2,
                paddingY: 1,
                marginLeft: 2,
                marginTop: 1,
              }}
            >
              <Box
                sx={{
                  color: "#78828C",
                  marginBottom: "10px",
                  marginX: 0.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Align items at the start and end
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#7B7B7B",
                  }}
                >
                  Units
                </Typography>
                <CustomTooltip title="The 'Units' refers to the possible list of units for each question in english. For Ex: If the question is 'What is the total area of your land?' then the 'units' can be a list of units such as {Acres, Bigha, etc.}" />
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                p={1}
                border={1}
                borderRadius={3}
                borderColor="grey.300"
                maxHeight={100} // Adjust the maxHeight as needed
                overflow="auto"
                backgroundColor="#ffffff"
              >
                {!isAddingUnit &&
                units &&
                units[selectedLanguage] &&
                units[selectedLanguage].length === 0 ? (
                  <Chip label="Add a unit" style={chipStyle} disabled={view}/>
                ) : (
                  units[selectedLanguage].map((unit, index) => (
                    <Chip
                      key={index}
                      label={unit}
                      onDelete={() => handleRemoveUnits(index)}
                      deleteIcon={<ClearIcon />}
                      style={chipStyle}
                      disabled={view}
                    />
                  ))
                )}
                {isAddingUnit && (
                  <TextField
                    value={newUnit}
                    onChange={handleChangeUnit}
                    onKeyPress={handleKeyPressUnit}
                    placeholder="Add new unit"
                    style={{
                      margin: "2px",
                      width: "150px",
                      fontSize: "12px",
                    }}
                    inputProps={{
                      style: { height: "30px", padding: "6px 12px" }, // Adjust height and padding
                    }}
                    disabled={view}
                  />
                )}
                <IconButton onClick={handleToggleAddingUnit} disabled={view}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        )}

        {((questionResponseType === "audio" &&
          questionType === "MCQ") ||  (questionResponseType === "text" &&
          questionType === "MCQ")) &&
          responses &&
          responses[selectedLanguage] &&
          responses[selectedLanguage].length !== 0 && (
            <Grid item md={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingX: 2,
                  paddingY: 1,
                  marginLeft: 2,
                  marginTop: 1,
                }}
              >
                <Box
                  sx={{
                    color: "#78828C",
                    marginRight: 3,
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between", // Align items at the start and end
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#7B7B7B",
                    }}
                    disabled={view}
                  >
                    Values
                  </Typography>
                  <CustomTooltip title="In the 'Values' column, include synonyms for all the entries listed under 'Responses'. Each entry in the 'Values' column corresponds to a synonym for the respective 'Responses'." />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column" // Change flex direction to column
                  p={1}
                  border={1}
                  borderRadius={3}
                  borderColor="grey.300"
                  maxHeight={300} // Adjust the maxHeight as needed
                  overflow="auto"
                  style={{ marginTop: 10, paddingLeft: 8 }}
                  backgroundColor="#ffffff"
                  marginRight={2}
                >
                  {Object.keys(responseDictionary[selectedLanguage]).map((response, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      borderBottom="1px solid #ccc"
                      padding="8px"
                    >
                      <Typography
                        style={{
                          width: "100px",
                          fontSize: "16px",
                          marginRight: "10px",
                          color: "#5E6060",
                        }}
                      >
                        {response}
                      </Typography>

                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        {responseDictionary[selectedLanguage][response]?.length > 0
                          ? responseDictionary[selectedLanguage][response].map(
                              (synonym, synonymIndex) => (
                                <Chip
                                  key={synonymIndex}
                                  label={synonym}
                                  onDelete={() =>
                                    handleRemoveSynonym(response, synonymIndex)
                                  }
                                  deleteIcon={<ClearIcon />}
                                  disabled={view}
                                  style={{
                                    margin: "4px",
                                    color: "#5E6060",
                                    backgroundColor: "#F1F1F1",
                                    fontSize: "16px",
                                    borderRadius: "1.5px",
                                  }}
                                />
                              )
                            )
                          : !isAddingSynonym && (
                              <Chip
                                label="Add synonym"
                                onClick={() =>
                                  handleToggleAddingSynonym(response)
                                }
                                style={{
                                  margin: "4px",
                                  cursor: "pointer",
                                  color: "#5E6060",
                                  backgroundColor: "#F1F1F1",
                                  fontSize: "14px",
                                  borderRadius: "1.5px",
                                }}
                                disabled={view}
                              />
                            )}
                        {isAddingSynonym && addingSynonymFor === response && (
                          <>
                            <TextField
                              value={newSynonym}
                              onChange={(e) => setNewSynonym(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleAddSynonym(response);
                                  handleToggleAddingSynonym(response); // Hide the TextField after adding a synonym
                                }
                              }}
                              placeholder="Enter synonym"
                              style={{
                                width: "150px",
                                fontSize: "12px",
                                margin: "4px",
                              }}
                              inputProps={{
                                style: { height: "30px", padding: "6px 12px" },
                              }}
                              disabled={view}
                            />
                          </>
                        )}
                        <IconButton
                          onClick={() => handleToggleAddingSynonym(response)}
                          disabled={view}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                      
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          )}
      </Collapse>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "12px",
            backgroundColor: "#EEEDED",
          }}
        >
          <IconButton
            onClick={() => setCollapsed(!collapsed)}
            sx={{
              "&:hover": { backgroundColor: "transparent" }, // Remove hover opacity
            }}
          >
            {collapsed ? (
              <>
                <Typography sx={{ color: "#2D6A4F", paddingX: 1 }}>
                  View Properties
                </Typography>
                <ArrowDropDownIcon />
              </>
            ) : (
              <>
                <Typography sx={{ paddingX: 1, color: "#2D6A4F" }}>
                  Close
                </Typography>
                <ArrowDropUpIcon />
              </>
            )}
          </IconButton>
        </Box>
        <IconButton
          onClick={handleDelete}
          sx={{
            "&:hover": { backgroundColor: "transparent" },
          }}
        >
          <Typography sx={{ color: "#2D6A4F", paddingX: 2 }}>Delete</Typography>
        </IconButton>
      </Box>
    </Grid>
  );
}
